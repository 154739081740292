import {nl2br} from '@ivosabev/helpers/nl2br';
import {Alert, Button, TextInput} from '@mantine/core';
import {type ActionFunctionArgs, type LoaderFunctionArgs, redirect} from '@remix-run/node';
import {useLoaderData} from '@remix-run/react';
import {type ZodIssue, ZodError, z} from 'zod';
import {getCurrentCycle} from '#/app/context/Cycles/getCurrentCycle.js';
import {getUserWithCredentials} from '#/app/context/Users/getUserWithCredentials.js';
import {requireAdminContext} from '#/app/helpers/Utils/requireAdminContext.server.js';
import {useFormAdvanced} from '#/app/hooks/useFormAdvanced.js';
import {useToastErrors} from '#/app/hooks/useToastErrors.js';
import {getSessionData, sessionLogin} from '#/app/utils/session.server.js';

const loginInputSchema = z.object({
  email: z.string().email().min(1, 'Email is required.'),
  password: z.string().min(1, 'Password is required.'),
});
// type LoginFormData = z.infer<typeof loginInputSchema>;

export async function action(args: ActionFunctionArgs) {
  const {request} = args;
  const input = Object.fromEntries(await request.formData());
  try {
    const context = await requireAdminContext(args);
    const {email, password} = loginInputSchema.parse(input);
    const user = await getUserWithCredentials(context, email, password);
    if (!user) {
      throw new ZodError([{code: 'custom', message: 'Wrong email or password.', path: []} as ZodIssue]);
    }
    const redirectTo = new URL(request.url).searchParams.get('redirect') || '/admin';
    const cycle = await getCurrentCycle(context, user.coopId);
    if (!cycle) {
      throw new ZodError([{code: 'custom', message: 'Cycle not found.', path: []} as ZodIssue]);
    }
    return await sessionLogin({coopId: user.coopId, cycleId: cycle.id, userId: user.id}, {redirectTo});
  } catch (e) {
    if (e instanceof ZodError) {
      return {
        data: input,
        errors: e.issues.map((issue) => ({code: issue.code, message: issue.message, path: issue.path})),
      };
    }
    throw e;
  }
};

export async function loader({request}: LoaderFunctionArgs) {
  const redirectTo = new URL(request.url).searchParams.get('redirect') || '/admin';
  const {userId} = await getSessionData(request);
  if (userId) {
    return redirect(redirectTo);
  }

  return {redirectTo};
};

export default function Login() {
  useToastErrors();

  const {redirectTo} = useLoaderData<typeof loader>();
  const form = useFormAdvanced({
    initialValues: {email: '', password: ''},
    schema: loginInputSchema,
  });

  return (
    <form.fetcher.Form action={`?redirect=${redirectTo}`} method="post" onSubmit={form.handleSubmit}>
      <div className="size-full min-h-screen bg-white pt-24">
        <div className="bordered mx-auto rounded-sm bg-white p-8 w-[410px]">
          <div className="flex flex-col gap-8">
            <img alt="KC Kosher Coop" className="w-3/5" src="/img/logo.png" />
            <div className="flex flex-col gap-4">
              <div className="text-3xl font-medium">Log in</div>
              {form.errors.$form && (
                <Alert
                  classNames={{
                    message: 'text-red-500 text-base',
                    root: 'bg-red-50 rounded-md p-3 border border-red-200',
                  }}
                  unstyled
                >{nl2br(form.errors.$form)}</Alert>
              )}
              <TextInput
                autoComplete="email"
                label="Email"
                placeholder="Enter your email"
                type="email"
                {...form.getInputProps('email')}
              />
              <TextInput
                autoComplete="current-password"
                label="Password"
                placeholder="Enter your password"
                type="password"
                {...form.getInputProps('password')}
              />
            </div>
            <div>
              <Button disabled={form.isLoading} type="submit">Log in</Button>
            </div>
          </div>
        </div>
      </div>
    </form.fetcher.Form>
  );
};
